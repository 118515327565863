<template>
  <div
    v-if="!item.hidden&&item.children"
    class="menu-wrapper">
    <template
      v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
      <app-link :to="resolvePath(onlyOneChild)">
        <el-menu-item
          :index="resolvePath(onlyOneChild)"
          :class="{'submenu-title-noDropdown':!isNest}">
          <div
            v-if="onlyOneChild.meta"
            class="menu-container">
            <i :class="onlyOneChild.meta.icon||item.meta.icon" :title="onlyOneChild.meta.title"/>
            <span v-html="onlyOneChild.meta.title" />
          </div>
        </el-menu-item>
      </app-link>
    </template>
    <el-submenu
      v-else
      ref="submenu"
      :index="resolvePath(item)">
      <template slot="title">
        <div
          v-if="item.meta"
          class="menu-container">
          <i :class="item.meta.icon" />
          <span v-html="item.meta.title" />
        </div>
      </template>
      <template v-for="child in item.children.filter(x => !x.hidden)">
        <sidebar-item
          v-if="child.children&&child.children.length>0"
          :key="child.path"
          :is-nest="true"
          :item="child"
          :base-path="resolvePath(child)"
          class="nest-menu" />
        <app-link
          v-else
          :key="child.name"
          :to="resolvePath(child)">
          <el-menu-item :index="resolvePath(child)">
            <div
              v-if="child.meta"
              class="menu-container">
              <i :class="child.meta.icon" :title="child.meta.title"/>
              <span v-html="child.meta.title" />
            </div>
          </el-menu-item>
        </app-link>
      </template>
    </el-submenu>
  </div>
</template>

<script>
import { isExternal } from '@/utils'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      onlyOneChild: null
    }
  },
  methods: {
    hasOneShowingChild (children, parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })
      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath (routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath
      }
      return this.$router.resolve({ name: routePath.name, params: this.$route.params }).href
    },
    isExternalLink (routePath) {
      return isExternal(routePath)
    }
  }
}
</script>
<style scoped>
.el-menu-item {
  white-space: pre-line;
  line-height: 40px;
  height: unset;
  min-height: 30px;
}

.el-submenu >>> .el-submenu__title {
  white-space: pre-line;
  line-height: 25px;
  height: unset;
  min-height: 30px;
}
</style>
