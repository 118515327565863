import { getToken, removeToken } from '@/modules/auth'
import Vue from 'vue'
import { hasPermission } from '@/store/modules/permission'

const SettingsKey = 'user-settings'

const user = {
  namespaced: true,

  state: {
    token: getToken(),
    id: null,
    profile: {},
    settings: {},
    roles: [],
    tempRoutes: {
      KpStorageInput: false,
      KpStorageCommentInput: false,
      StorageInput: false
    }
  },

  getters: {
    hasPermission: state => roles => hasPermission(state.roles, roles)
  },

  mutations: {
    SET_PROFILE: (state, profile) => {
      state.profile = profile
      state.username = profile.USER_FIO ?? profile.USER_ESOV_LOGIN ?? 'Не задано'
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_TABLE_OPTIONS: (state, { options, tableKey }) => {
      Vue.set(state.settings.tableOptions, tableKey, options)
    },
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_TEMP_ROUTES: (state, data) => {
      state.tempRoutes = data
    }
  },

  actions: {
    GetUserInfo ({ commit, state }, href = '/backend/auth/info') {
      return fetch(href, {
        credentials: 'include',
        headers: {
          Authorization: 'Bearer ' + state.token
        }
      }).then(response => response.json())
        .then(data => {
          if (!data) {
            throw new Error('request doesn\'t return data')
          }
          if (data.roles && data.roles.length > 0) {
            commit('SET_ROLES', data.roles)
            commit('SET_ID', data.id)
            commit('SET_TEMP_ROUTES', data.tempRoutes)
          } else {
            throw new Error('getInfo: roles must be a non-null array !')
          }
          return data
        })
    },

    FedLogout ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', undefined)
        commit('SET_ROLES', [])
        removeToken()
        resolve()
      })
    },

    UpdateTableOptions ({ commit, dispatch }, payload) {
      commit('SET_TABLE_OPTIONS', payload)
      dispatch('UpdateSettings')
    },

    UpdateSettings ({ state }) {
      window.localStorage.setItem(SettingsKey, JSON.stringify(state.settings))
    }
  }
}

export default user
