<template>
  <div class="navbar">
    <hamburger
      v-if="!hamburgerHidden"
      :toggle-click="toggleSideBar"
      :is-active="sidebar.opened"
      class="hamburger-container" />
    <h4 class="header-text">{{ $route.meta.navbarTitle }}</h4>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Hamburger from '@/components/Hamburger'

export default {
  components: {
    Hamburger
  },
  props: {
    hamburgerHidden: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'device'
    ])
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('toggleSideBar')
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.nav {
  margin-left: 20px;
  font-size: 14px;
}

.s-i {
  float: right;
  font-size: 14px;
  color: grey;
  margin-right: 10px;
}

.navbar {
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  margin-top: -60px;
  border-radius: 0 !important;
  position: sticky;
  top: 0;
  width: 99%;
  z-index: 1999;
  background: white;
  border-bottom: 1px solid #ebeef5;

  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      margin: 0 8px;
    }

    .international {
      vertical-align: top;
    }
  }
  .header-text {
    margin-left: 50px;
    height: 100%;
    white-space: nowrap;
    display: block;
    color: grey;
    text-overflow: ellipsis;
  }
}
</style>
