import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import '@/styles/index.scss'
import 'element-ui/lib/theme-chalk/index.css'
import { defaultConfig, torisInit } from '@/modules/auth'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faSignInAlt, fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import './permission'
import VueKatex from 'vue-katex'
import 'katex/dist/katex.min.css'

Vue.use(VueKatex, {
  globalOptions: {}
})

library.add(faSignInAlt)
library.add(fas)
library.add(far)
dom.watch()

Vue.use(ElementUI, { locale })

Vue.config.productionTip = false

torisInit(defaultConfig(store, router, ElementUI.Message.error))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
