import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from './modules/auth'
import { hasPermission } from '@/store/modules/permission'
import { sleep } from './utils'

NProgress.configure({ showSpinner: false })

const whiteList = ['/denied']

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  // Ждём получения или не получения токена
  while (getToken() === undefined) {
    await sleep(100)
  }
  if (getToken()) {
    // if (store.getters.roles.length === 0) {
    //   next()
    // }
    if (store.getters.roles.length === 0) {
      store.dispatch('user/GetUserInfo').then(({ roles }) => {
        if (roles.join() === 'guest') {
          next({ name: 'denied', replace: true })
        } else {
          store.dispatch('GenerateRoutes', { roles }).then(() => {
            store.state.permission.addRouters.forEach((route) => {
              router.addRoute(route)
            })
            if (to.path === '/desktop') {
              if (store.getters.roles.includes('operator')) {
                if (store.state.user.tempRoutes.StorageInput) {
                  next({ path: '/storage/list', replace: true })
                } else {
                  next({ path: '/rating', replace: true })
                }
              } else if (store.getters.roles.includes('vice_governor')) {
                next({ path: '/kp-result', replace: true })
              } else {
                next({ ...to, replace: true })
              }
            } else {
              next({ ...to, replace: true })
            }
          })
        }
      }).catch((err) => {
        store.dispatch('user/FedLogout').then(() => {
          Message.error(err || 'Ошибка верификации, пожалуйста попробуйте ещё раз.')
          next({ path: '/' })
        })
      })
    } else if (hasPermission(store.getters.roles, to.meta.roles)) {
      next()
    } else {
      next({ path: '/401', replace: true, query: { noGoBack: true } })
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    window.location = store.state.config.TORIS_DOMAIN + '/?login=yes&back_url=' + window.location.origin
  }
})

router.afterEach(() => {
  NProgress.done()
})
