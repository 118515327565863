<template>
  <div
    id="app">
    <router-view />
    <el-backtop />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>
