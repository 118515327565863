import { asyncRouterMap, constantRouterMap } from '@/router'
import store from '@/store'

export function hasPermission (roles, permissionRoles) {
  return roles.indexOf('admin') >= 0 ||
    !permissionRoles ||
    roles.some(role => permissionRoles.indexOf(role) >= 0)
}

function filterAsyncRouter (routes, roles) {
  const res = []
  const tempRoutes = store.state.user.tempRoutes
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp.meta && tmp.meta.roles)) {
      if (tmp.children) {
        tmp.children = filterAsyncRouter(tmp.children, roles, tempRoutes)
      }
      if (!tmp.meta?.tempRoute || tempRoutes[tmp.meta.tempRoute]) {
        res.push(tmp)
      }
    }
  })
  return res
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    GenerateRoutes ({ commit }, { roles }) {
      return new Promise(resolve => {
        commit('SET_ROUTERS', filterAsyncRouter(asyncRouterMap, roles))
        resolve()
      })
    }
  }
}

export default permission
