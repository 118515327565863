import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/views/layout/Layout'

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/denied',
    name: 'denied',
    component: () => import('@/views/access/denied'),
    hidden: true
  },
  {
    path: '/404',
    component: Layout,
    hidden: true,
    props: {
      pathName: '404'
    },
    children: [
      {
        path: '/404',
        component: () => import('@/views/errorPage/404'),
        hidden: true
      }
    ]
  },
  {
    path: '/401',
    component: () => import('@/views/errorPage/401'),
    hidden: true
  },
  {
    path: '',
    redirect: '/desktop',
    hidden: true
  }
]

export const asyncRouterMap = [
  {
    path: '/desktop',
    component: Layout,
    meta: {
      roles: ['technologist', 'control']
    },
    children: [
      {
        path: '/desktop',
        component: () => import('@/views/Desktop.vue'),
        name: 'desktop',
        meta: {
          title: 'Рабочий стол',
          icon: 'fas fa-desktop',
          navbarTitle: 'Полнота представления данных мониторинга КПР'
        }
      }
    ]
  },
  {
    path: '/request/list',
    component: Layout,
    meta: {
      roles: ['technologist', 'control']
    },
    children: [
      {
        path: '/request/list',
        component: () => import('@/views/Requests.vue'),
        name: '/request/list',
        meta: {
          title: 'Журнал Запросы',
          navbarTitle: 'Запросы на ввод данных в закрытый отчетный период',
          icon: 'fas fa-th-list'
        }
      },
      {
        path: '/request/view/:id(\\d+)',
        component: () => import('@/views/RequestView.vue'),
        name: '/request/view',
        hidden: true,
        meta: {
          navbarTitle: 'Просмотр запроса'
        }
      }
    ]
  },
  {
    path: '/control/pki',
    component: Layout,
    meta: {
      roles: ['technologist', 'control']
    },
    children: [
      {
        path: '/control/pki',
        component: () => import('@/views/controlPki/ControlPki.vue'),
        name: '/control/pki/list',
        meta: {
          title: 'Журнал КПР',
          icon: 'fas fa-th-list',
          navbarTitle: 'Ключевые показатели результативности'
        }
      },
      {
        path: '/control/pki/view/:id(\\d+)',
        component: () => import('@/views/controlPki/PkiView.vue'),
        name: '/control/pki/view',
        hidden: true,
        meta: {
          navbarTitle: 'Просмотр показателя'
        }
      },
      {
        path: '/control/pki/update/:id(\\d+)',
        component: () => import('@/views/controlPki/PkiDetail.vue'),
        name: '/control/pki/update',
        hidden: true,
        meta: {
          navbarTitle: 'Редактирование показателя'
        }
      },
      {
        path: '/control/pki/create',
        component: () => import('@/views/controlPki/PkiDetail.vue'),
        name: '/control/pki/create',
        hidden: true,
        meta: {
          navbarTitle: 'Создание показателя'
        }
      },
      {
        path: '/control/pki/history/:id(\\d+)',
        component: () => import('@/views/controlPki/PkiHistory.vue'),
        name: '/control/pki/history',
        hidden: true,
        meta: {
          navbarTitle: 'Просмотр изменения'
        }
      }
    ]
  },
  {
    path: '/control/symbols',
    component: Layout,
    meta: {
      roles: ['technologist', 'control']
    },
    children: [
      {
        path: '/control/symbols/list',
        component: () => import('@/views/symbols/Symbols.vue'),
        name: '/control/symbols/list',
        meta: {
          title: 'Журнал Параметры',
          icon: 'fas fa-th-list',
          navbarTitle: 'Параметры'
        }
      },
      {
        path: '/control/symbols/view/:id(\\d+)',
        component: () => import('@/views/symbols/SymbolsView.vue'),
        name: '/control/symbols/view',
        hidden: true,
        meta: {
          navbarTitle: 'Обновление параметров'
        }
      },
      {
        path: '/control/symbols/create/',
        component: () => import('@/views/symbols/SymbolsView.vue'),
        name: '/control/symbols/create',
        hidden: true,
        meta: {
          navbarTitle: 'Создание параметров'
        }
      }
    ]
  },
  {
    path: '/control/period',
    component: Layout,
    meta: {
      roles: ['technologist', 'control']
    },
    children: [
      {
        path: '/control/period/list',
        component: () => import('@/views/periods/Period.vue'),
        name: '/control/period/list',
        meta: {
          title: 'Журнал Периоды',
          navbarTitle: 'Периоды',
          icon: 'fas fa-th-list'
        }
      },
      {
        path: '/control/period/view/:id(\\d+)',
        component: () => import('@/views/periods/PeriodView.vue'),
        name: '/control/period/view',
        hidden: true,
        meta: {
          navbarTitle: 'Обновление периода'
        }
      },
      {
        path: '/control/period/create/',
        component: () => import('@/views/periods/PeriodView.vue'),
        name: '/control/period/create',
        hidden: true,
        meta: {
          navbarTitle: 'Создание периода'
        }
      }
    ]
  },
  // {
  //   path: '/pki',
  //   component: Layout,
  //   meta: {
  //     roles: ['technologist']
  //   },
  //   children: [
  //     {
  //       path: '/pki',
  //       component: () => import('@/views/directories/KprList.vue'),
  //       name: '/pki',
  //       meta: {
  //         title: 'Список КПР',
  //         navbarTitle: 'КПР',
  //         icon: 'fas fa-th-list'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/control/fact-period',
    component: Layout,
    meta: {
      roles: ['admin']
    },
    children: [
      {
        path: '/control/fact-period/list',
        component: () => import('@/views/factPeriods/Period.vue'),
        name: '/control/fact-period/list',
        meta: {
          title: 'Журнал Периоды (факт)',
          navbarTitle: 'Периоды',
          icon: 'fas fa-th-list'
        }
      },
      {
        path: '/control/fact-period/view/:id(\\d+)',
        component: () => import('@/views/factPeriods/PeriodView.vue'),
        name: '/control/fact-period/view',
        hidden: true,
        meta: {
          navbarTitle: 'Обновление периода'
        }
      }
    ]
  },
  {
    path: '/storage',
    component: Layout,
    meta: {
      tempRoute: 'StorageInput',
      roles: ['operator']
    },
    children: [
      {
        path: '/storage/list',
        component: () => import('@/views/storage/Storage.vue'),
        name: '/storage/list',
        meta: {
          title: 'Ввод КПР',
          icon: 'fas fa-th-list',
          navbarTitle: 'Доступные КПР'
        }
      },
      {
        path: '/storage/overall',
        component: () => import('@/views/storage/StorageOverall.vue'),
        name: '/storage/overall',
        hidden: true,
        meta: {
          navbarTitle: 'Ввод общего КПР'
        }
      }
    ]
  },
  {
    path: '/storage/view',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/storage/view/:id(\\d+)',
        component: () => import('@/views/storage/StorageView.vue'),
        name: '/storage/view',
        hidden: true,
        meta: {
          navbarTitle: 'Просмотр показателя'
        }
      }
    ]
  },
  {
    path: '/storage/update',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/storage/update/:id(\\d+)',
        component: () => import('@/views/storage/StorageDetail.vue'),
        name: '/storage/update',
        hidden: true,
        meta: {
          navbarTitle: 'Редактирование показателя'
        }
      }
    ]
  },
  {
    path: '/rating',
    component: Layout,
    meta: {
      roles: ['technologist', 'control', 'operator']
    },
    children: [
      {
        path: '/rating',
        component: () => import('@/views/Rating.vue'),
        name: 'rating',
        meta: {
          title: 'Рейтинг',
          icon: 'fas fa-th-list',
          navbarTitle: 'Рейтинговая таблица'
        }
      }
    ]
  },
  {
    path: '/monitoring',
    component: Layout,
    meta: {
      roles: ['technologist']
    },
    children: [
      {
        path: '/monitoring',
        component: () => import('@/views/Monitoring.vue'),
        name: 'monitoring',
        meta: {
          title: 'Результаты мониторинга',
          icon: 'fas fa-th-list'
        }
      }
    ]
  },
  {
    path: '/control/vice-governor',
    component: Layout,
    name: 'directories',
    meta: {
      title: 'Справочники',
      icon: 'fas fa-folder'
    },
    alwaysShow: true,
    children: [
      {
        path: '/control/vice-governor/list',
        component: () => import('@/views/directories/ViceGovernor.vue'),
        name: '/control/vice-governor/list',
        meta: {
          title: 'Вице-губернаторы',
          navbarTitle: 'Вице-губернаторы',
          roles: ['technologist', 'control', 'vice_governor']
        }
      },
      {
        path: '/control/vice-governor/view/:id(\\d+)',
        component: () => import('@/views/directories/ViceGovernorView.vue'),
        name: '/control/vice-governor/view',
        hidden: true,
        meta: {
          navbarTitle: 'Обновление вице-губернатора'
        }
      },
      {
        path: '/control/vice-governor/create/',
        component: () => import('@/views/directories/ViceGovernorView.vue'),
        name: '/control/vice-governor/create',
        hidden: true,
        meta: {
          navbarTitle: 'Создание вице-губернатора'
        }
      },
      {
        path: '/control/ebosp/list',
        component: () => import('@/views/directories/Ebosp.vue'),
        name: '/control/ebosp/list',
        meta: {
          title: 'ИОГВ'
        }
      },
      {
        path: '/control/ebosp/view/:id(\\d+)',
        component: () => import('@/views/directories/EbospView.vue'),
        name: '/control/ebosp/view',
        hidden: true,
        meta: {
          navbarTitle: 'Обновление ИОГВ'
        }
      },
      {
        path: '/control/ebosp/create/',
        component: () => import('@/views/directories/EbospView.vue'),
        name: '/control/ebosp/create',
        hidden: true,
        meta: {
          navbarTitle: 'Создание ИОГВ'
        }
      },
      {
        path: '/control/pki-classification/list',
        component: () => import('@/views/directories/Classifiers.vue'),
        name: '/control/pki-classification/list',
        meta: {
          title: 'Классификаторы',
          navbarTitle: 'Классификаторы КПР',
          roles: ['technologist', 'control']
        }
      },
      {
        path: '/control/pki-classification/view/:id(\\d+)',
        component: () => import('@/views/directories/ClassifiersView.vue'),
        name: '/control/pki-classification/view',
        hidden: true,
        meta: {
          navbarTitle: 'Обновление классификатора'
        }
      },
      {
        path: '/control/pki-classification/create/',
        component: () => import('@/views/directories/ClassifiersView.vue'),
        name: '/control/pki-classification/create',
        hidden: true,
        meta: {
          navbarTitle: 'Создание классификатора'
        }
      },
      {
        path: '/control/main-direction-activity/list',
        component: () => import('@/views/directories/MainDirectionActivity.vue'),
        name: '/control/main-direction-activity/list',
        meta: {
          title: 'Основные направления деятельности',
          roles: ['technologist', 'control']
        }
      },
      {
        path: '/control/main-direction-activity/view/:id(\\d+)',
        component: () => import('@/views/directories/MainDirectionActivityView.vue'),
        name: '/control/main-direction-activity/view',
        hidden: true,
        meta: {
          navbarTitle: 'Обновление направления деятельности'
        }
      },
      {
        path: '/control/main-direction-activity/create/',
        component: () => import('@/views/directories/MainDirectionActivityView.vue'),
        name: '/control/main-direction-activity/create',
        hidden: true,
        meta: {
          navbarTitle: 'Создание направления деятельности'
        }
      },
      {
        path: '/control/unit-of-measurement/list',
        component: () => import('@/views/directories/Units.vue'),
        name: '/control/unit-of-measurement/list',
        meta: {
          title: 'Единицы измерения',
          navbarTitle: 'Единицы измерения',
          roles: ['technologist', 'control']
        }
      },
      {
        path: '/control/unit-of-measurement/view/:id(\\d+)',
        component: () => import('@/views/directories/UnitsView.vue'),
        name: '/control/unit-of-measurement/view',
        hidden: true,
        meta: {
          navbarTitle: 'Обновление единицы измерения'
        }
      },
      {
        path: '/control/unit-of-measurement/create/',
        component: () => import('@/views/directories/UnitsView.vue'),
        name: '/control/unit-of-measurement/create',
        hidden: true,
        meta: {
          navbarTitle: 'Создание единицы измерения'
        }
      }
    ]
  },
  {
    path: '/control/desktop',
    component: Layout,
    name: 'kpi-directories',
    meta: {
      title: 'Контрольные показатели',
      icon: 'fas fa-folder'
    },
    alwaysShow: true,
    children: [
      {
        path: '/control/desktop',
        component: () => import('@/views/DesktopKP.vue'),
        name: '/control/desktop',
        meta: {
          title: 'Рабочий стол КП',
          navbarTitle: 'Полнота представления данных мониторинга',
          roles: ['technologist', 'control', 'vice_governor']
        }
      },
      {
        path: '/control/kp',
        component: () => import('@/views/KP.vue'),
        name: '/control/kp/list',
        meta: {
          title: 'Журнал КП',
          navbarTitle: 'Показатели результативности ИОГВ для ежемесячного информирования Губернатора Санкт-Петербурга',
          roles: ['technologist', 'control'],
          isKP: true
        }
      },
      {
        path: '/control/kp/view/:id(\\d+)',
        component: () => import('@/views/controlPki/PkiView.vue'),
        name: '/control/kp/view',
        hidden: true,
        meta: {
          navbarTitle: 'Просмотр показателя',
          isKP: true
        }
      },
      {
        path: '/control/kp/update/:id(\\d+)',
        component: () => import('@/views/controlPki/PkiDetail.vue'),
        name: '/control/kp/update',
        hidden: true,
        meta: {
          navbarTitle: 'Редактирование показателя',
          isKP: true
        }
      },
      {
        path: '/control/kp/create',
        component: () => import('@/views/controlPki/PkiDetail.vue'),
        name: '/control/kp/create',
        hidden: true,
        meta: {
          navbarTitle: 'Создание показателя',
          isKP: true
        }
      },
      {
        path: '/kp-storage/list',
        component: () => import('@/views/storage/StorageKp.vue'),
        name: '/kp-storage/list',
        meta: {
          title: 'Ввод КП',
          navbarTitle: 'Доступные КП',
          tempRoute: 'KpStorageInput',
          isKP: true,
          roles: ['operator']
        }
      },
      {
        path: '/kp-storage/overall',
        component: () => import('@/views/storage/StorageOverall.vue'),
        name: '/kp-storage/overall',
        hidden: true,
        meta: {
          navbarTitle: 'Ввод КП',
          isKP: true
        }
      },
      {
        path: '/kp-storage/view/:id(\\d+)',
        component: () => import('@/views/storage/StorageKpView.vue'),
        name: '/kp-storage/view',
        hidden: true,
        meta: {
          navbarTitle: 'Просмотр показателя',
          isKP: true
        }
      },
      {
        path: '/kp-storage/update/:id(\\d+)',
        component: () => import('@/views/storage/StorageKpDetail.vue'),
        name: '/kp-storage/update',
        hidden: true,
        meta: {
          navbarTitle: 'Редактирование показателя',
          isKP: true
        }
      },
      {
        path: '/kp-result',
        component: () => import('@/views/KPResult.vue'),
        name: '/kp-result',
        meta: {
          title: 'Итог',
          navbarTitle: 'Итоговая таблица'
        }
      }
    ]
  },
  {
    path: '/kp-result/history',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/kp-result/history',
        component: () => import('@/views/KPResultHistory.vue'),
        name: '/kp-result/history',
        meta: {
          navbarTitle: 'Историчность скачивания'
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
