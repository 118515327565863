const defaultEnv = {
  TORIS_CODE: 'urn:eis:toris:rpk',
  TORIS_DOMAIN: 'https://test.toris.gov.spb.ru',
  TORIS_DNS: 'test.toris.gov.spb.ru',
  TORIS_PROTO: 'https:'
}

const configMap = {
  'https://kpr.iac.spb.ru': {
    TORIS_DOMAIN: 'https://toris.gov.spb.ru',
    TORIS_DNS: 'toris.gov.spb.ru'
  }
}

const resolveEnv = () => {
  return configMap[window.location.origin] || {}
}

export default {
  namespaced: true,

  state: Object.assign(defaultEnv, resolveEnv())
}
