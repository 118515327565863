<template>
  <section class="app-main">
    <transition
      name="fade-transform"
      mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key () {
      return this.$route.fullPath
    }
  }
}
</script>

<style scoped>
.app-main {
  min-height: calc(100vh - 173px);
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>
